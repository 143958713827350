<template>
 <v-container fluid>
  <v-card max-width="500">
    <v-card-text>
      <v-treeview
        ref="treeView"
        item-key="key"
        :items="items"
        :search="search"
        :filter="filter"
        :open.sync="open"
        activatable
        shaped rounded
        dense
      >
        <template v-slot:prepend="{ item }">
          <v-icon :color="getColorForType(item.type)">{{`mdi-${getIconForType(item.type)}`}}</v-icon>
        </template>
        <template v-slot:append="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon
                v-bind="attrs"
                v-on="on"
                v-if="item.type == 'Area' || item.type == 'Country' || item.type == 'SalesGroup' || item.type == 'SalesGroupRepresentative'"
                @click="onAssignChildrenClick(item)"
              >
                <v-icon rounded>mdi-playlist-edit</v-icon>
              </v-btn>
            </template>
            <span>Assign child elements</span>
          </v-tooltip>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
  <assign-to-area-dlg ref="assignToAreaDlg" @dataChanged="onDataChanged($event)"></assign-to-area-dlg>
  <assign-to-country-dlg ref="assignToCountryDlg" @dataChanged="onDataChanged($event)"></assign-to-country-dlg>
  <assign-to-sales-group-dlg ref="assignToSalesGroupDlg" @dataChanged="onDataChanged($event)"></assign-to-sales-group-dlg>
  <assign-to-sales-representative-dlg ref="assignToSalesRepresentativeDlg" @dataChanged="onDataChanged($event)"></assign-to-sales-representative-dlg>

    <v-overlay opacity="0" v-if="loading">
      <v-progress-circular
        indeterminate
        color="red"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import api from '@/api.js';
import assignToAreaDlg from '@/components/dialogs/AssignToAreaDlg.vue';
import assignToCountryDlg from '@/components/dialogs/AssignToCountryDlg.vue';
import assignToSalesGroupDlg from '@/components/dialogs/AssignToSalesGroup.vue';
import assignToSalesRepresentativeDlg from '@/components/dialogs/AssignToSalesRepresentative.vue';
export default {
  name: 'OrgTree',
  data: () => ({
    items: [],
    open: [1, 2],
    search: null,
    caseSensitive: false,
    loading: false
  }),
  mounted() {
    this.loadData();
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  methods: {

    async refresh() {
       const me = this;
       me.loading = true;
       await me.loadData();
       me.loading = false;
    },

    async loadData() {
      this.loading = true;
      const res = await api.get("Tree");
      this.items = res.data.children;
      this.loading = false;
    },
    onAssignChildrenClick(item) {
      const me = this;
      if (item.type === "Area") {
        me.$refs.assignToAreaDlg.show(item);
      }
      else if (item.type === "Country") {
        me.$refs.assignToCountryDlg.show(item);
      }
      else if (item.type === "SalesGroup") {
        me.$refs.assignToSalesGroupDlg.show(item);
      }
      else if (item.type === "SalesGroupRepresentative") {
        me.$refs.assignToSalesRepresentativeDlg.show(item);
      }

    },
    getIconForType(type) {
      if (type === 'Country') return 'map-marker';
      if (type === 'AreaManager') return 'account-tie';
      if (type === 'CountryManager') return 'account-tie';
      if (type === 'SalesGroupManager') return 'account-tie';
      if (type === 'SalesGroupRepresentative') return 'account';      
      if (type === 'SalesGroup') return 'account-group';
      if (type === 'POS') return 'warehouse';
      return 'earth';
    },
    getColorForType(type) {
      if (type === 'Country') return 'primary';
      if (type === 'AreaManager') return 'primary';
      if (type === 'CountryManager') return 'primary';
      if (type === 'SalesGroupManager') return 'primary';
      if (type === 'SalesGroupRepresentative') return 'primary';      
      if (type === 'SalesGroup') return 'primary';
      if (type === 'pos') return 'primary';
      return 'primary';
    },

    onDataChanged(data) {
      data.old.children = data.new.children;      
    }
    
  },
  components: {
    assignToAreaDlg,
    assignToCountryDlg,
    assignToSalesGroupDlg,
    assignToSalesRepresentativeDlg
  },
};

</script>
