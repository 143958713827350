<template>
  <div class="main-component">
    <router-view></router-view>
  </div>
</template>
<style lang="scss">
html {
  overflow-y: auto;
} /* to avoid unnecessary scrollbar on the right, see https://stackoverflow.com/questions/56973002/vuetify-adds-scrollbar-when-its-not-needed" */
.footer {
  font-size: 14px !important;
  color: #2196f3;
}
</style>

<script>
export default {
  name: "App",  
};
</script>
