import api from '@/api.js';
import helpers from '@/common/helpers.js';

export default {

    async initialize() {
        this.recordId = this.$route.params.id;
        console.log(this.recordId);
        this.requiredRules.push(v => !!v || "Input is required.");
        await this.refresh();
    },

    async loadRecord() {
        const me = this;
        console.log("loadRecord");
        const res = await api.get(`${me.url}/${me.recordId}`);
        me.record = res.data;
    },

    async refresh() {
        const me = this;
        console.log("refresh start");
        if (this.recordId == -1) {
            this.record = this.createNewRecord();
        } else {
            await this.loadRecord();
        }
        me.recordOriginal = helpers.deepClone(this.record);
        me.isDirty = false;
        this.$emit('IsDirtyChanged', false);
        console.log("refresh finished");
        console.log(me.record);
    },

    async saveAndNext(next) {
        const success = await this.save();
        if (success == true) next();
    },

    async saveAndBack() {
        const success = await this.save();
        if (success == true) this.goBack();
    },    

    async save() {
        await this.onBeforeSave();

        const me = this;
        this.$refs.form.validate();
        if (me.validateAdditional) {
            this.valid = me.validateAdditional();
        }
        if (this.valid == false) {
            // this.$alert({
            //     title: "Validation errors",
            //     text: "The data could not be saved. Please correct the validation errors and try again.",
            //     acceptText: "OK"
            // });
            this.$error("Please correct the validation errors.");
            this.onValidationErrors();
            return false;
        }
        let res;
        try {
            res = await api.post(me.url, this.record);
        } catch (err) {
            const msg = this.getSaveErrorMessage(err);
            this.$error("The record could not be saved. " + msg);
            this.throw(err);
        }
        me.recordId = res.data.Id;
        me.refresh();
        me.isDirty = false;
        me.$emit('IsDirtyChanged', false);
        return true;
    },

    delete() {
        const me = this;
        me.$confirm(
            "Do you really want to delete the current record?", {
                title: "Delete record?",
                buttonTrueText: "Yes",
                buttonFalseText: "No",
                icon: "mdi-comment-question-outline",
                color: '#e30613'
            }
        ).then(res => {
            if (res == true) {
                this.deleteRecord();
            }
        });
    },

    async deleteRecord() {
        console.log("deleteRecord");
        let res;
        try {
            res = await api.delete(`${this.url}/${this.record.Id}`);
        } catch (err) {
            const msg = this.getDeleteErrorMessage(err);
            this.$error("The record could not be deleted. " + msg);
            this.throw(err);
        }
        if (res.data == "HasDependencies") {
            this.$error("This record cannot not be deleted. It is assigned to other data in the Organisation tree.");
            return;
        }
        this.isDirty = false;
        // this.$alert({
        //     text: "The record was deleted.",
        //     acceptText: 'OK'
        // });
        this.goBack();
    },

    getDeleteErrorMessage(err) {
        console.log("getDeleteErrorMessage");
        console.log(err);
        if (err.response && err.response.data) {
            if (err.response.data.indexOf("FK_AreaToCountry_Country") > 0) return "It is already assigned to other data in the Organisation tree.";
            if (err.response.data.indexOf("FK_CountryToSalesGroup_SalesGroup") > 0) return "It is already assigned to other data in the Organisation tree.";

        }
        return "An unexpected error occured.";
    },
    getSaveErrorMessage(err) {
        console.log("getSaveErrorMessage");
        console.log(err.response.data);
        if (err.response && err.response.data) {
            if (err.response.data.indexOf("IX_Employee_Email") > 0) return "This E-Mail-address is already assigned to another employee.";
            if (err.response.data.indexOf("IX_Employee_NameFirstName") > 0) return "An employee with the same name and first name already exists.";
            if (err.response.data.indexOf("IX_SalesGroup") > 0) return "A sales group with the same name already exists.";
            if (err.response.data.indexOf("IX_Country") > 0) return "A country with the same name already exists.";
            if (err.response.data.indexOf("IX_Area_Name") > 0) return "An area with the same name already exists.";
        }
        return "An unexpected error occured.";
    },

    undo() {
        this.record = this.recordOriginal;
        this.recordOriginal = helpers.deepClone(this.record);
        this.isDirty = false;
        this.$emit('IsDirtyChanged', false);
    },

    goBack() {
        this.$router.go(-1);
    },

    save_isDisabled() {
        return this.isDirty == false;
    },

    undo_isDisabled() {
        return this.isDirty == false;
    },

    delete_isDisabled() {        
        if(this.record.Id === undefined)
        {
            return true;
        }
        return false;
    },

    onInput() {
        this.isDirty = true;
        this.$emit("IsDirtyChanged", true);
    },

    onKeydown(ea) {
        console.log(ea);
        const me = this;
        if (ea.ctrlKey == true && ea.code == 'KeyS') {
            ea.stopPropagation();
            ea.preventDefault();
            ea.cancelBubble = true;
            me.saveAndBack();
            return false;
        }
        if (ea.ctrlKey == true && ea.key == 'z') {
            ea.stopPropagation();
            ea.preventDefault();
            ea.cancelBubble = true;
            me.undo();
            return false;
        }
        // if (ea.key == "Tab") return;
        // if (ea.key == "Shift") return;
        // if (ea.key == "Control") return;
        // if (ea.key == "Enter") return;
        // if (ea.key == "Escape") return;
        // if (ea.key == "ArrowRight") return;
        // if (ea.key == "ArrowLeft") return;
        // if (ea.key == "ArrowUp") return;
        // if (ea.key == "ArrowDown") return;
        // this.isDirty = true;
        // this.$emit("IsDirtyChanged", true);
    },

    confirmSaveChangesBeforeLeave(next) {
        const me = this;
        if (!this.isDirty) {
            next();
            return;
        }

        me.$confirm(
            "Do you want to save your changes?", {
                title: "Save changes?",
                buttonTrueText: "Yes",
                buttonFalseText: "No",
                icon: "mdi-comment-question-outline",
                color: '#525f6b'
            }
        ).then(res => {
            if (res == true) {
                me.saveAndNext(next);
            } else {
                next();
            }
        })
    }
}