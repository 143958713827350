<template>
  <v-container fluid>
    <v-row>
      <v-dialog v-model="dialog" scrollable width="560">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar dark>
              <v-toolbar-title>Assign to {{ salesGroup.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="cancel()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 450px;">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on }">
                <v-checkbox
                  v-model="showUnassignedOnly"
                  style="margin-top: 20px; margin-left: 20px"
                  label="Only show unassigned"
                  @click="refreshFilter()"
                  v-on="on"
                >
                </v-checkbox>
              </template>
              <span>Uncheck to remove existing assignments</span>
            </v-tooltip>

            <v-tabs>
              <v-tab
                ><v-icon class="mr-2">mdi-account-tie</v-icon>Sales-Group Manager</v-tab
              >
              <v-tab-item :transition="false">
                <v-data-table
                  style="margin-top: 12px"
                  dense
                  :items-per-page="itemsPerPage"
                  :headers="salesGroupManagerHeaders"
                  :items="salesGroupManagers"
                  v-model="salesGroupManagersAssigned"
                  :single-select="false"
                  show-select
                  hide-default-footer
                  item-key="Id"
                >
                  <template v-slot:[`item.isChecked`]="{ item }">
                    <v-checkbox
                      class="shrink ml-0 mr-0 mt-0 mb-0"
                      dense
                      v-model="item.isChecked"
                      @change="setIsDirty()"
                    ></v-checkbox>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab
                ><v-icon class="mr-2">mdi-account</v-icon>Sales Representatives</v-tab
              >
              <v-tab-item :transition="false">
                <v-data-table
                  style="margin-top: 12px"
                  dense
                  :items-per-page="itemsPerPage"
                  :headers="salesRepresentativesHeaders"
                  :items="salesRepresentatives"
                  v-model="salesRepresentativesAssigned"
                  show-select
                  hide-default-footer
                  item-key="Id"
                >
                  <template v-slot:[`item.isChecked`]="{ item }">
                    <v-checkbox
                      class="shrink ml-0 mr-0 mt-0 mb-0"
                      dense
                      v-model="item.isChecked"
                      @change="setIsDirty()"
                    ></v-checkbox>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false"> Cancel </v-btn>
            <v-btn width="100" @click="save"> OK </v-btn>
          </v-card-actions>
        </v-card>
        <v-overlay opacity="0" v-if="loading">
          <v-progress-circular
            indeterminate
            color="red"
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import api from "@/api.js";
//import helpers from '@/common/helpers.js';

export default {
  name: "assign-to-sales-group-dlg",
  // mixins: [msalMixin],
  mounted() {},
  data: () => ({
    loading: false,
    itemsPerPage:  -1,
    salesGroup: { name: "" },
    dialog: false,
    isDirty: false,
    showUnassignedOnly: false,
    persistedChildren: [],
    salesGroupManagersAssigned: [],
    salesGroupManagersUnassigned: [],
    salesGroupManagers: [],
    salesGroupManagersAll: [],
    salesGroupManagerHeaders: [
      { text: "First Name", value: "FirstName" },
      { text: "Last Name", value: "LastName" },
    ],
    salesRepresentatives: [],
    salesRepresentativesAll: [],
    salesRepresentativesUnassigned: [],
    salesRepresentativesAssigned: [],
    salesRepresentativesHeaders: [
      { text: "First Name", value: "FirstName" },
      { text: "Last Name", value: "LastName" },
    ],
  }),
  methods: {
    async loadData() {
      const me = this;

      me.loading = true;
      me.salesGroupManagers = [];
      me.salesRepresentatives = [];
      
      let res = await api.get("Employees/ByRole?RoleId=3"); //Sales-Group Manager: RoleId = 3
      me.salesGroupManagersAll = res.data;

      res = await api.get("Employees/ByRole?RoleId=4"); //Sales-Representative: RoleId = 4
      me.salesRepresentativesAll = res.data;

      res = await api.get("Unassigned/SalesGroupRepresentatives");
      me.salesRepresentativesUnassigned = res.data;

      res = await api.get("Unassigned/SalesGroupManagers");
      me.salesGroupManagersUnassigned = res.data;

      res = await api.get(`SalesGroup/${me.salesGroup.id}/Children`);
      me.persistedChildren = res.data;

      me.salesGroupManagersAssigned = me.salesGroupManagersAll.filter((x) =>
        this.persistedChildren.Managers.includes(x.Id)
      );
      me.salesRepresentativesAssigned = me.salesRepresentativesAll.filter((x) =>
        this.persistedChildren.Representatives.includes(x.Id)
      );

      me.refreshFilter();

      me.loading = false;

    },

    refreshFilter() {
      const me = this;
      me.salesGroupManagers =
        me.showUnassignedOnly == true
          ? me.salesGroupManagersUnassigned
          : me.salesGroupManagersAll;

      me.salesRepresentatives =
        me.showUnassignedOnly == true
          ? me.salesRepresentativesUnassigned
          : me.salesRepresentativesAll;
    },

    async show(salesGroup) {
      this.salesGroup = salesGroup;
      this.dialog = true;
      await this.loadData();
      this.isDirty = false;
    },

    setIsDirty() {
      this.isDirty = true;
    },
    async save() {
      const me = this;

      const postData = {
        Managers: me.salesGroupManagersAssigned.map((x) => x.Id),
        Representatives: me.salesRepresentativesAssigned.map((x) => x.Id),
      };
      const url = `SalesGroup/${me.salesGroup.id}/Children`;
      await api.post(url, postData);
      const res = await api.get(me.salesGroup.path);
      me.$emit("dataChanged", { old: me.salesGroup, new: res.data });
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>

