<template>
  <v-container fluid >
  <v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="520"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark>
            <v-toolbar-title>Assign to {{area.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 450px;">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on }">
              <v-checkbox 
                v-model="showUnassignedOnly"
                style="margin-top:20px;margin-left:20px"
                label="Only show unassigned"
                @click="refreshFilter()"
                v-on="on">
              </v-checkbox>
            </template>
            <span>Uncheck to remove existing assignments</span>
          </v-tooltip>
          <v-tabs>
            <v-tab><v-icon class="mr-2">mdi-account-tie</v-icon>Area Manager</v-tab>
              <v-tab-item :transition="false">
                <v-data-table style="margin-top:12px"
                    dense
                    :items-per-page="itemsPerPage"
                    :headers="areaManagerHeaders"
                    :items="areaManagers"
                    v-model="areaManagersAssigned"
                    :single-select="false"
                    show-select
                    hide-default-footer
                    item-key="Id"
                  >
                    <template slot="no-data" v-if="loading == true">Loading data...</template>
                    <template slot="no-data" v-if="loading == false">No records found.</template>
                    <template v-slot:[`item.isChecked`]='{ item }'>
                      <v-checkbox class="shrink ml-0 mr-0 mt-0 mb-0" dense
                        v-model='item.isChecked'
                        @change="setIsDirty()"
                      ></v-checkbox>
                    </template>
                  </v-data-table>
              </v-tab-item>
            <v-tab><v-icon class="mr-2">mdi-map-marker</v-icon>Countries</v-tab>
              <v-tab-item :transition="false">
                <v-data-table style="margin-top:12px"
                    dense
                    :items-per-page="itemsPerPage"
                    :headers='countryHeaders'
                    :items='countries'
                    v-model="countriesAssigned"
                    show-select
                    hide-default-footer
                    item-key='Id'
                  >
                    <template slot="no-data" v-if="loading == true">Loading data...</template>
                    <template slot="no-data" v-if="loading == false">No records found.</template>
                    <template v-slot:[`item.isChecked`]='{ item }'>
                      <v-checkbox class="shrink ml-0 mr-0 mt-0 mb-0" dense
                        v-model='item.isChecked'
                        @change="setIsDirty()"
                      ></v-checkbox>
                    </template>
                  </v-data-table>
              </v-tab-item>
          </v-tabs>      
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            width="100"
            @click="save"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card> 
      <v-overlay opacity="0" v-if="loading">
        <v-progress-circular
          indeterminate
          color="red"
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>    
  </v-row>
  </v-container>
</template>
<script>
import api from '@/api.js';
//import helpers from '@/common/helpers.js';

export default {
  name: 'assign-to-area-dlg',
  // mixins: [msalMixin],
  mounted() {
  },
  data: () => ({
    loading: false,
    area: {name: ''},
    dialog: false,
    isDirty: false,
    showUnassignedOnly: false,
    persistedChildren: [],
    itemsPerPage: -1,
    countriesAssigned: [],
    countriesUnassigned: [],
    countries: [],
    countriesAll: [],
    countryHeaders: [
      { text: 'Country Name', value: 'CountryName' },
    ],

    areaManagersAssigned: [],
    areaManagersUnassigned: [],
    areaManagers: [],
    areaManagersAll: [],
    areaManagerHeaders: [
      { text: 'First Name', value: 'FirstName' },
      { text: 'Last Name', value: 'LastName' },
    ],
  }),
  methods: {
    async loadData() {
      const me = this;

      me.countries = [];
      me.areaManagers = [];

      this.loading = true;

      let res = await api.get("Employees/ByRole?RoleId=1");
      me.areaManagersAll = res.data;

      res = await api.get("Unassigned/AreaManagers");
      me.areaManagersUnassigned = res.data;

      res =  await api.get(`Area/${me.area.id}/Children`);
      me.persistedChildren = res.data;

      me.areaManagersAssigned = me.areaManagersAll.filter(x => 
        this.persistedChildren.Managers.includes(x.Id)
      );

      res = await api.get("Countries");
      me.countriesAll = res.data;

      res = await api.get("Unassigned/Countries");
      me.countriesUnassigned = res.data;

      me.countriesAssigned = me.countriesAll.filter(x => 
        this.persistedChildren.Countries.includes(x.Id)
      );

      me.refreshFilter();      
      this.loading = false;
    },

    refreshFilter() {
      const me = this;
      me.areaManagers = me.showUnassignedOnly == true 
        ? me.areaManagersUnassigned 
        : me.areaManagersAll;

      me.countries = me.showUnassignedOnly == true 
        ? me.countriesUnassigned
        : me.countriesAll;
    },

    async show(area) {
      this.dialog = true;
      this.area = area;
      await this.loadData();
      this.isDirty = false;
    },

    setIsDirty() {
      this.isDirty = true;
    },

    async save() {
      const me = this;

      const postData = {
        Managers: me.areaManagersAssigned.map(x=> x.Id),
        Countries: me.countriesAssigned.map(x=> x.Id),
      };
      const url = `Area/${me.area.id}/Children`;
      const res = await api.post(url, postData);
      console.log(res);      
      const res2 = await api.get(me.area.path);
      this.dialog = false;
      me.$emit("dataChanged", {old: me.area, new: res2.data} );
    },

    cancel() {
      this.dialog = false;
    },
  },
};
</script>

