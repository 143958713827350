import axios from 'axios';
import Vue from 'vue'

const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 100000000,
    params: {}, // do not remove this, its added to add params later in the config
    headers: {
        common: {
            'Content-Type': 'application/json',
            'accept-language': 'de-DE',
        },
    },
});

api.interceptors.request.use((config) => {
    try {        
        let msal = Vue.prototype.$msal;
        if(msal.isAuthenticated())
        {
            return msal.readApiToken().then((token) => {
                if (token !== null) {
                    config.headers.authorization = `Bearer ${token}`;
                    console.log(`axios header: ${config.headers.authorization}`);
                    return Promise.resolve(config);
                }
                else
                {
                    console.log('axios.interceptors Token was NULL');
                    return Promise.resolve(config);
                }
            });
        }
        else
        {
            console.log('axios.interceptors is NOT Authenticated.');
        }
    } catch (err) {
        console.log('Error getting auth/token');
        console.log(err);
    }
});

api.interceptors.response.use((response) => response, (error) => {
    console.log('axios.interceptors error:');
    console.log(error);
    console.log(error.data);
    console.log(error.status);
    console.log(error.response);
    if (error.response.status == 401) {
        Vue.prototype.$msal.signOut();
        return;
    }
    return Promise.reject(error);
});

export default api;