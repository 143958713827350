var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"max-width":"500"}},[_c('v-card-text',[_c('v-treeview',{ref:"treeView",attrs:{"item-key":"key","items":_vm.items,"search":_vm.search,"filter":_vm.filter,"open":_vm.open,"activatable":"","shaped":"","rounded":"","dense":""},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getColorForType(item.type)}},[_vm._v(_vm._s(("mdi-" + (_vm.getIconForType(item.type)))))])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.type == 'Area' || item.type == 'Country' || item.type == 'SalesGroup' || item.type == 'SalesGroupRepresentative')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onAssignChildrenClick(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"rounded":""}},[_vm._v("mdi-playlist-edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Assign child elements")])])]}}])})],1)],1),_c('assign-to-area-dlg',{ref:"assignToAreaDlg",on:{"dataChanged":function($event){return _vm.onDataChanged($event)}}}),_c('assign-to-country-dlg',{ref:"assignToCountryDlg",on:{"dataChanged":function($event){return _vm.onDataChanged($event)}}}),_c('assign-to-sales-group-dlg',{ref:"assignToSalesGroupDlg",on:{"dataChanged":function($event){return _vm.onDataChanged($event)}}}),_c('assign-to-sales-representative-dlg',{ref:"assignToSalesRepresentativeDlg",on:{"dataChanged":function($event){return _vm.onDataChanged($event)}}}),(_vm.loading)?_c('v-overlay',{attrs:{"opacity":"0"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"red","size":"64"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }