<template>
  <v-container fluid id="employees">
  <span>Employees</span>
    <v-text-field
      ref="searchTextBox"
      v-model="search" outlined clearable
      append-icon="mdi-magnify"
      label="Search"
      @keyup="onSearchKeyUp($event)"
      class="mx-0 mt-4 mb-0 pa-0 pb-0"
    ></v-text-field>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      item-key="Id"
      display-text="FirstName"
      class="elevation-1 mt-0 pt-0"
      @click:row="onClickRow($event)"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
    <template slot="no-data" v-if="loading == true">Loading data...</template>
    <template slot="no-data" v-if="loading == false">No records found.</template>
    </v-data-table>

    <v-overlay opacity="0" v-if="loading">
      <v-progress-circular
        indeterminate
        color="red"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<style lang="scss">
  #employees tr.v-data-table__selected {
    background: green !important;
  }
</style>
<script>
import api from '@/api.js';

export default {
  name: "employee-view",

  props: {
  },

  data: () => ({
    loading: false,
    itemsPerPage: -1,
    items: [],
    headers: [],
    search: ""
  }),

  async created() {
    const me = this;    
    me.loading = false;
    me.headers = me.createHeaders();
    me.refresh();
  },

  mounted() {
    setTimeout(() => {
      this.$refs.searchTextBox.focus();
    }, 300);
  },

  updated() {
    document.title = "Jura Orga App | Employees";
    this.$emit("SubComponentChanged", this);
  },

  methods: {

    onSearchKeyUp() {
      this.refresh();
    },

    async refresh() {
       const me = this;
       me.loading = true;
       const res = await api.get(`Employees?SearchString=${me.search}`);
        me.items = res.data;
        me.loading = false;
    },

    addNew() {
      this.$router.push(`employees/-1`);
    },

    onClickRow(e) {
      this.$router.push(`employees/${e.Id}`);
    },

    createHeaders() {
      //const me = this;
      const headers = [
        {
          text: "First Name",
          align: "start",
          sortable: true,
          value: "FirstName",
        },
        {
          text: "Last Name",
          align: "start",
          sortable: true,
          value: "LastName",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "Email",
        },
        {
          text: "Roles",
          align: "start",
          sortable: true,
          value: "RoleNames",
        },
      ];
      return headers;
    },
  },
};
</script>