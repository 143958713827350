export default {
    url: '',
    loaded: true,
    isDirty: false,
    recordId: null,
    record: {},
    recordOriginal: {},
    rules: [],
    requiredRules: [],
    valid: false,
}