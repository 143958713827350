<template>
  <v-container fluid>
  <span>Sales Group Details</span>
    <v-form
      class="mt-4"
        ref="form" v-model="valid"
        v-if="loaded"
      >
      <v-row>
        <v-col cols="auto" style="min-width:350px;max-width:500px">
          <v-card elevation="2" class="pa-8">
            <v-text-field ref="txtSalesGroupName" dense
              :rules="requiredRules" 
              required 
              @focus="$event.target.select()" 
              label="Sales Groups Name" 
              v-model="record.SalesGroupName"
              @input="onInput($event)"
              @keydown="onKeydown($event)"
              > 
            </v-text-field>
          </v-card>
        </v-col>
      </v-row>  
    </v-form>
  </v-container>
</template>
<style lang="scss">
  h1 {
    font-size: 18px;
    color:#8f98a2;
    margin-bottom: 12px;
    margin-left: 4px;
  }

  .required label::after {
      content: "*";
  }  
</style>
<script>
import detailViewData from '@/common/detailViewData.js';
import detailViewMethods from '@/common/detailViewMethods.js';

export default {
  name: "salesGroups-details-view",

  props: {},

  data: () => ({
    ...detailViewData,
  }),

  created() {
    this.init();
  },

  mounted() {
    setTimeout(() => {
      this.$refs.txtSalesGroupName.focus();
    }, 300);
  },

  updated() {
    document.title = "Jura Orga App | Sales-Group Details";
  },

  beforeRouteLeave (to, from, next) {
    this.confirmSaveChangesBeforeLeave(next);
  },  

  methods: {
    ...detailViewMethods,

    async init() {
      this.url = "SalesGroup";
      await this.initialize();
    },

    createNewRecord() {
      const rec = {
        SalesGroupName: ""
      };
      return rec;
    },

    onBeforeSave(){
    },
    onValidationErrors(){
      //console.log("onValidationErrors called in AreaDetailsView");
      
    },

  },
};
</script>