import Vue from 'vue'
import VueRouter from 'vue-router'
import AppFrame from '../AppFrame.vue'
import AreasView from '../views/AreasView.vue'
import AreaDetailsView from '../views/AreaDetailsView.vue'
import CountriesView from '../views/CountriesView.vue'
import CountryDetailsView from '../views/CountryDetailsView.vue'
import EmployeesView from '../views/EmployeesView.vue'
import EmployeeDetailsView from '../views/EmployeeDetailsView.vue'
import OrgStructureView from '../views/OrgStructureView.vue'
import SalesGroupsView from '../views/SalesGroupsView.vue'
import SalesGroupDetailsView from '../views/SalesGroupDetailsView.vue'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'AppFrame',
    component: AppFrame,
    children: [{
            path: '/areas',
            name: 'AreasView',
            component: AreasView
        },
        {
            path: '/areas/:id',
            name: 'AreaDetailsView',
            component: AreaDetailsView
        },
        {
            path: '/countries',
            name: 'CountriesView',
            component: CountriesView
        },
        {
            path: '/countries/:id',
            name: 'CountryDetailsView',
            component: CountryDetailsView
        },
        {
            path: '/employees',
            name: 'EmployeesView',
            component: EmployeesView,
        },
        {
            path: '/employees/:id',
            name: 'EmployeeDetailsView',
            component: EmployeeDetailsView,
        },
        {
            path: '/orgStructure',
            name: 'OrgStructureView',
            component: OrgStructureView
        },
        {
            path: '/salesGroups',
            name: 'SalesGroupsView',
            component: SalesGroupsView
        },
        {
            path: '/salesGroups/:id',
            name: 'SalesGroupDetailsView',
            component: SalesGroupDetailsView,
        },
    ]
}, ];

const router = new VueRouter({
    mode: 'history',
    hash: false,
    routes: routes,
});

router.beforeEach((to, from, next) => {
    console.log("beforeRouteLeave");
    console.log(to);
    console.log(from);
    console.log(next);
    next();
});

export default router