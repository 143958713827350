<template>
  <v-app>
    <v-navigation-drawer app 
      :color="sideMenuColor" v-bind:width="240" 
      v-model="showSideBar" 
      mobile-breakpoint="600">
      <v-row>
        <v-col><router-link to="/"><v-img src="./logo.png" max-width="100" style="margin-left:10px;margin-top:10px"></v-img></router-link></v-col>
        <v-col><p style="margin-top:15px;font-size:12px">Employee Assignment App</p></v-col>
      </v-row>
      <v-row>
        <sideMenu :user="user"></sideMenu>
      </v-row>
    </v-navigation-drawer>

    <v-app-bar app ref="appBar">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon
              v-bind="attrs"
              v-on="on"
              @click="showSideBar = !showSideBar"
            ></v-app-bar-nav-icon>
          </template>
          <span>Toggle navigation bar</span>
        </v-tooltip>

        <menu-command
          CommandName="goBack" 
          IconName="mdi-arrow-left-circle"
          TooltipText="Go back"
          @Execute="onMenuCommandExecute($event)">
        </menu-command>

        <menu-command
          CommandName="refresh" 
          IconName="mdi-refresh"
          TooltipText="Reload data"
          @Execute="onMenuCommandExecute($event)">
        </menu-command>

        <menu-command
          CommandName="undo" 
          IconName="mdi-undo"
          TooltipText="Undo"
          @Execute="onMenuCommandExecute($event)">
        </menu-command>
        
        <menu-command
          CommandName="saveAndBack" 
          IconName="mdi-content-save"
          TooltipText="Save"
          @Execute="onMenuCommandExecute($event)">
        </menu-command>

        <menu-command
          CommandName="addNew" 
          IconName="mdi-plus"
          TooltipText="Add new record"
          @Execute="onMenuCommandExecute($event)">
        </menu-command>

        <menu-command
          CommandName="delete" 
          IconName="mdi-delete"
          TooltipText="Delete record"
          @Execute="onMenuCommandExecute($event)">
        </menu-command>

        <v-spacer></v-spacer>

        <v-menu :offset-y="true" open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"              
              icon
              dark 
              v-bind="attrs"
              v-on="on"
              @click="onUserAccountClick"
            >              
              <v-icon>mdi-account</v-icon>              
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, action) in accountMenuItems"
              :key="action" link @click="onUserAccountMenuClick(item.action)"
            >
              <v-list-item-icon class="me-2">
                <v-icon >{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid >

        <!-- If using vue-router -->
        <router-view  
          ref="routerview" 
          :user="user" 
          :globalUserSettings="globalUserSettings" 
          @IsDirtyChanged="onIsDirtyChanged($event)"          
          @SubComponentChanged="onSubComponentChanged($event)"          
          >
        </router-view>
      </v-container>
    </v-main>

    <v-footer app>  
        <span class="px-4 footer">Version {{versionNo}}</span>
        <span class="px-4 footer">User: {{this.$msal.username}}</span>
  </v-footer>
</v-app>
</template>
<style lang="scss">
  html { overflow-y: auto } /* to avoid unnecessary scrollbar on the right, see https://stackoverflow.com/questions/56973002/vuetify-adds-scrollbar-when-its-not-needed" */
  .footer {
    font-size:14px!important;color:#2196F3;
  }
</style>
<script>
import menuCommand from './components/other/MenuCommand.vue';
import sideMenu from './components/other/SideMenu.vue';

export default {
  name: 'AppFrame',

  components: {
    sideMenu,
    menuCommand,
  },

  data: () => ({
    globalUserSettings: null,
    sideMenuColor: null,
    isAppInitialized: false,
    versionNo: '0.25',
    accountMenuItems: [],
    user: {},
    showSideBar: true,
    isDirty: false,
    username: 'unknown...'
  }),

  created() {
    const self = this;
    window.EventBus.$on('signedIn', (res) => {
            console.log(`signedIn: ${res.data.username}`);
            self.onSignedOn();
    });
  },
  
  mounted()
  {
    this.sideMenuColor = "#e0e2e7";    
    this.loadData();
  },
  updated() {
    document.title = "Employee Assignment App";
    //updated after the route has changed

    const v = this.$refs.routerview;
    if (v != null) {
      if (v.getNavigateBackwardText) this.navigateBackwardMenuText = v.getNavigateBackwardText();
      if (v.getNavigateForwardText) this.navigateForwardMenuText = v.getNavigateForwardText();
      if (v.getNavigateToText) this.navigateToMenuText = v.getNavigateToText();      
      this.updateCurrentComponentState();
    }
  },

  methods:
  {
    onMenuCommandExecute(e) {
      if (! this.$refs.routerview) return;
      this.$refs.routerview[e]();
    },
    loadData() {
      this.accountMenuItems = this.getAccountMenuItems();
    },
    onUserAccountClick() {
      this.openUserAccountDialog();
    },

    onUserAccountMenuClick(action) {
      if (action == 'openUserAccount') this.openUserAccountDialog(this.globalUserSettings);
      else if (action == 'logout') this.logout();
    },
    
    updateCurrentComponentState()
    {
      if (! this.$refs.routerview) return;
      const appBar = this.$refs.appBar;
      appBar.$children.forEach(child => {
        if (child.updateCanExecute != null) child.updateCanExecute(this.$refs.routerview);
      });
    },
    onSignedOn() {
      console.log('onSignedOn called. AppFrame.vue');
      this.loadData();
    },
    async logout() {
      this.$msal.signOut();
    },
    openUserAccountDialog()
    {
      // if (! this.$refs.routerview) return;      
      // this.$refs.userAccountDialog.show(this.globalUserSettings);      
    },
    onUserAccountOK() 
    {
      // if (! this.$refs.routerview) return;
      // if (this.$refs.routerview.updateHeaders != null) {
      //   this.$refs.routerview.updateHeaders();        
      // }
      // this.accountMenuItems = this.getAccountMenuItems(); //To force re-translation
    },      
    getAccountMenuItems() {
      return [
        { action: 'openUserAccount', title: this.$msal.username, icon:'mdi-account' },
        { action: 'logout', title: "Sign off", icon:'mdi-logout' },
      ];
    },

    onSubComponentChanged() {
      this.updateCurrentComponentState();
    },

    onIsDirtyChanged(e) {
        this.isDirty = e;
        this.updateCurrentComponentState();
    },


  },
  
};
</script>
