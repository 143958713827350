<template>
  <v-container fluid>
  <span>Employee Details</span>
    <v-form
      class="mt-4"
        ref="form" v-model="valid"
        v-if="loaded"
      >
      <v-row>
        <v-col cols="auto" style="min-width:350px;max-width:500px">
          <v-card elevation="2" class="pa-8">
            <v-text-field ref="txtFirstName" dense
              :rules="requiredRules" 
              required 
              @focus="$event.target.select()" 
              label="First Name" 
              v-model="record.FirstName"
              @input="onInput($event)"
              @keydown="onKeydown($event)"
              >
            </v-text-field>
            <v-text-field ref="txtLastName" dense
              :rules="requiredRules" 
              required 
              @focus="$event.target.select()" 
              label="Last Name" 
              v-model="record.LastName"
              @input="onInput($event)"
              @keydown="onKeydown($event)"
              >
            </v-text-field>
            <v-text-field ref="txtMail" dense
              label="E-mail"
              :rules="emailRules"
              v-model="record.Email"
              required
              @input="onInput($event)"
              @keydown="onKeydown($event)"
              >
              </v-text-field>
          </v-card>
        </v-col>
        <v-col cols="auto" style="min-width:350px;max-width:4ß00px">
            <v-data-table elevation="2" 
              @item-selected="onRoleChange"
              :headers="rolesHeaders"
              :items="roles"
              :items-per-page="itemsPerPage"
              item-key="Id"
              display-text="RoleName"
              class="elevation-1"
              show-select
              hide-default-footer              
              v-model="record.Roles"
            >
            </v-data-table>
            <span v-if="roleRequiredError" style="color:#e30613;font-size:12px">
              At least one role needs to be assigned.
            </span>
        </v-col>
      </v-row>  
    </v-form>
  </v-container>
</template>
<style lang="scss">
  h1 {
    font-size: 18px;
    color:#8f98a2;
    margin-bottom: 12px;
    margin-left: 4px;
  }

</style>
<script>
import detailViewData from '@/common/detailViewData.js';
import detailViewMethods from '@/common/detailViewMethods.js';
import api from '@/api.js';
export default {
  name: "employee-details-view",

  data: () => ({
    ...detailViewData,
    emailRules: [],
    roleRequiredError: false,
    rolesHeaders: [],
    roles: [],
    itemsPerPage:  -1,
    selectedRoleIds: []
  }),

  created() {
    this.init();
  },

  mounted() {
    setTimeout(() => {
      this.$refs.txtFirstName.focus();
    }, 300);
  },

  updated() {
    document.title = "Jura Orga App | Employee Details";
  },

  beforeRouteLeave (to, from, next) {
    this.confirmSaveChangesBeforeLeave(next);
  },  

  methods: {
    ...detailViewMethods,

    async init() {
      this.url = "Employee";
      await this.initialize();
     
      this.selectedRoleIds = this.record.Roles.map(x => x.Id);
      this.rolesHeaders = [
        {
          text: "Roles",
          align: "start",
          sortable: false,
          value: "RoleName",
        }
      ];
      const res = await api.get("Roles");
      this.roles = res.data;
    },

    createNewRecord() {
      const rec = {
        FirstName: "",
        LastName: "",
        Email: "",
        Roles: []
      };
      return rec;
    },

    onRoleChange() {
        this.isDirty = true;
        this.$emit("IsDirtyChanged", true);
    },

    async onBeforeSave(){
      console.log("onBeforeSave called in EmployeeDetailsView");
      this.emailRules = [];
      this.emailRules.push(v => !!v || "Input is required.");
      this.emailRules.push(v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Invalid E-mail address");
      
      let self = this;
      return new Promise(
        function (resolve) {
        setTimeout( function(){
                self.$refs.txtMail.validate();
                resolve(true);
              }, 0);
        }
      );
    },

    onValidationErrors(){
      //console.log("onValidationErrors called in EmployeeDetailsView");
      

    },
  },
};
</script>