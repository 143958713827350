<template>
  <v-container fluid>
    <v-row>
      <v-dialog v-model="dialog" width="800" scrollable>
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar dark>
              <v-toolbar-title>Assign to {{ country.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="cancel()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text  style="height: 450px;">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on }">
                <v-checkbox
                  v-model="showUnassignedOnly"
                  style="margin-top: 20px; margin-left: 20px"
                  label="Only show unassigned"
                  @click="refreshFilter()"
                  v-on="on"
                >
                </v-checkbox>
              </template>
              <span>Uncheck to remove existing assignments</span>
            </v-tooltip>

            <v-tabs dense>
              <v-tab
                ><v-icon class="mr-2">mdi-account-tie</v-icon>Country
                Manager</v-tab
              >
              <v-tab-item :transition="false">
                <v-data-table
                  style="margin-top: 12px"
                  dense
                  :items-per-page="itemsPerPage"
                  :headers="countryManagerHeaders"
                  :items="countryManagers"
                  v-model="countryManagersAssigned"
                  :single-select="false"
                  show-select
                  hide-default-footer
                  item-key="Id"
                >
                  <template slot="no-data" v-if="loading == true">Loading data...</template>
                  <template slot="no-data" v-if="loading == false">No records found.</template>
                  <template v-slot:[`item.isChecked`]="{ item }">
                    <v-checkbox
                      class="shrink ml-0 mr-0 mt-0 mb-0"
                      dense
                      v-model="item.isChecked"
                      @change="setIsDirty()"
                    ></v-checkbox>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab
                ><v-icon class="mr-2">mdi-account-group</v-icon>Sales
                Groups</v-tab
              >
              <v-tab-item :transition="false">
                <v-data-table
                  style="margin-top: 12px"
                  dense
                  :items-per-page="itemsPerPage"
                  :headers="salesGroupHeaders"
                  :items="salesGroups"
                  v-model="salesGroupsAssigned"
                  show-select
                  hide-default-footer
                  item-key="Id"
                >
                  <template v-slot:[`item.isChecked`]="{ item }">
                    <v-checkbox
                      class="shrink ml-0 mr-0 mt-0 mb-0"
                      dense
                      v-model="item.isChecked"
                      @change="setIsDirty()"
                    ></v-checkbox>
                  </template>
                  <template slot="no-data" v-if="loading == true">Loading data...</template>
                  <template slot="no-data" v-if="loading == false">No records found.</template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false"> Cancel </v-btn>
            <v-btn width="100" @click="save"> OK </v-btn>
          </v-card-actions>
        </v-card>
        <v-overlay opacity="0" v-if="loading">
          <v-progress-circular
            indeterminate
            color="red"
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import api from "@/api.js";
//import helpers from '@/common/helpers.js';

export default {
  name: "assign-to-country-dlg",
  // mixins: [msalMixin],
  mounted() {},
  data: () => ({
    loading: false,
    itemsPerPage:  -1,
    country: { name: "" },
    dialog: false,
    isDirty: false,
    showUnassignedOnly: false,
    persistedChildren: [],
    countryManagersAssigned: [],
    countryManagersUnassigned: [],
    countryManagers: [],
    countryManagersAll: [],
    countryManagerHeaders: [
      { text: "First Name", value: "FirstName" },
      { text: "Last Name", value: "LastName" },
    ],
    salesGroups: [],
    salesGroupsAll: [],
    salesGroupsUnassigned: [],
    salesGroupsAssigned: [],
    salesGroupHeaders: [{ text: "Name", value: "SalesGroupName" }],
  }),
  methods: {
    async loadData() {
      const me = this;

      me.loading = true;
      me.salesGroups = [];
      me.countryManagers = [];
      
      let res = await api.get("SalesGroups");
      me.salesGroupsAll = res.data;

      res = await api.get("Unassigned/SalesGroups");
      me.salesGroupsUnassigned = res.data;

      res = await api.get("Employees/ByRole?RoleId=2"); //Country Manager: RoleId = 2
      me.countryManagersAll = res.data;

      res = await api.get("Unassigned/CountryManagers");
      me.countryManagersUnassigned = res.data;

      res = await api.get(`Country/${me.country.id}/Children`);
      me.persistedChildren = res.data;

      me.countryManagersAssigned = me.countryManagersAll.filter((x) =>
        this.persistedChildren.Managers.includes(x.Id)
      );
      me.salesGroupsAssigned = me.salesGroupsAll.filter((x) =>
        this.persistedChildren.SalesGroups.includes(x.Id)
      );

      me.refreshFilter();
      me.loading = false;
    },

    refreshFilter() {
      const me = this;
      me.countryManagers =
        me.showUnassignedOnly == true
          ? me.countryManagersUnassigned
          : me.countryManagersAll;

      me.salesGroups =
        me.showUnassignedOnly == true
          ? me.salesGroupsUnassigned
          : me.salesGroupsAll;
    },

    async show(country) {
      this.dialog = true;
      this.country = country;
      console.log("me.country");
      console.log(country);
      await this.loadData();
      this.isDirty = false;
    },

    setIsDirty() {
      this.isDirty = true;
    },

    async save() {
      const me = this;

      const postData = {
        Managers: me.countryManagersAssigned.map((x) => x.Id),
        SalesGroups: me.salesGroupsAssigned.map((x) => x.Id),
      };
      const url = `Country/${me.country.id}/Children`;
      const res = await api.post(url, postData);
      console.log(res);
      console.log("me.country.path");
      console.log(me.country.path);
      const res2 = await api.get(me.country.path);
      this.dialog = false;
      me.$emit("dataChanged", { old: me.country, new: res2.data });
    },

    cancel() {
      this.dialog = false;
    },
  },
};
</script>

